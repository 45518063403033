<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white">
        <div class="">
          <CogsBarChart></CogsBarChart>
        </div>
        <div class="mt-8">
          <CogsCharttextOnly></CogsCharttextOnly>
        </div>
        <div class="mt-8 pt-8">
          <CogsByProduct></CogsByProduct>
        </div>
        <div class="mt-8">
          <CogsTextOnly></CogsTextOnly>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import CogsBarChart from './amazonBarLisiting.vue';
import CogsByProduct from './amazonChannelBar.vue';
import CogsCharttextOnly from './amazonProductTextOnly.vue';
import CogsTextOnly from './amazonChannelTextOnly.vue';

export default {
  components: {
    CogsByProduct,
    CogsTextOnly,
    CogsBarChart,
    CogsCharttextOnly
},
  data () {
    return {
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    document.title = 'COGS ◾ Amazon'
  },
  watch: {
  },
  methods: {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
</style>
