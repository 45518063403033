<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white grid grid-cols-12 " :style="`height:` +  mainHeight + `px; !important`">
        <div class=" mx-2 shadow-md relative" :class="isFilterOpen ? 'box col-span-9' : 'box col-span-12'">
          <div class="bg-white cust_card p-2 heading-3 text-text1 justify-between flex">
            <p>COGS by Channel (Amazon)</p>
            <div class="top-0 absolute" :class="isFilterOpen ? '-right-4' : 'transition-all delay-75 -right-2'" >
              <button id="toggle" class="bg-gray-600 hover:bg-gray-700 transition delay-75 w-11 h-11 rounded-l-full " @click="openCloseFilter()">
                <span v-if="isFilterOpen"><i class="fas fa-caret-right h-6 w-6 text-white mt-1"></i></span>
                <p v-if="!isFilterOpen"><i class="fas fa-caret-left h-6 w-6 text-white mt-1"></i></p>
              </button>
            </div>
          </div>
          <div style="overflow-y: auto; white-space: nowrap;overflow-x: hidden;" class="table_containder" v-if="ExtFListingObj.length > 0 && firstLoading">
            <div :style="`max-height:` +  cardHight + `px; !important`" class="pt-2">
              <div id="chart">
                <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
              </div>
            </div>
          </div>
          <div class="layout m-2" v-if="ExtFListingObj.length === 0 && firstLoading">
            <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
              <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">Info</span>
              <div>
                <p class=" font-medium heading-4">No record found.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box col-span-3 overflow-y-auto mx-2 shadow-md transition-transform delay-300 ease-in-out transition-2" id="mainDiv" v-if="isFilterOpen">
          <div class="bg-white">
            <div class=" bg-primary2 p-2 items-center justify-between flex h-11 sticky top-0">
              <div class="heading-4 text-white font-bold">Filter</div>
              <span class="centerLine">
                <div class=" text-white cursor-pointer hover:underline mr-2" @click="resetAll()">
                  <button style="letter-spacing: 1px;" class="border border-gray1 px-3 py-1 cust_card heading-7">Reset</button>
                </div>
                <div class=" text-white cursor-pointer hover:underline" @click="applyFilter()">
                  <button style="letter-spacing: 1px;" class="border border-gray1 px-3 py-1 cust_card heading-7">Apply</button>
                </div>
              </span>
            </div> 
            <div class="pt-1">
              <div v-if="filterObject.startDate !== '' || filterObject.endDate !== ''">
                <p class="text-text1 heading-6 font-bold pl-2">
                  <span class="text-text2 heading-6 font-bold">{{filterObject.startDate | DMMYYYYddd}}</span> 
                  To
                  <span class="text-text2 heading-6 font-bold">{{filterObject.endDate | DMMYYYYddd}}</span>
                </p>
              </div>
              <div class="pt-1 pl-1">
                <button style="letter-spacing: 1px"
                v-for="(data, index) in buttonFiltersArray" :key="index"
                  @click="getDateTimeForFilterHandler(index, true)"
                  :class="data.selected ? 'text-white bg-primary2' : 'text-primary2 bg-white hover:bg-blue-50'"
                  class="m-1 px-1 py-0.5 heading-7 rounded-md border border-primary2"
                  >{{data.title}}</button
                >
              </div>
            </div>
            <div class="divider m-1"></div>
            <div class="p-2 my-1 cust_card group" >
              <div class="centerLine justify-between ">
                <div class="centerLine">
                  <span class=" text-text2 heading-6 font-bold">Order Requested Shipping</span>
                  <div class="loader2 ease-linear ml-2" v-if="isOrderReqLoading"></div>
                </div>
                <div class="centerLine  pt-2 pb-1">
                  <div class="pr-2 cursor-pointer" @click="showOrderReqLoader = !showOrderReqLoader">
                    <span v-if="!showOrderReqLoader"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                    <p v-if="showOrderReqLoader"><i class="fas fa-times h-4 w-4 text-gray4"></i></p>
                  </div>
                  <div class="pr-2 ">
                    <button  @click="resetOrderReq()" style="letter-spacing: 0.5px;" class="border border-gray-400 rounded-md px-2 heading-7 cursor-pointer">Clear</button>
                  </div>
                  <span @click="selectedAll('itemNameList')" class="centerLine cursor-pointer" v-if="parentOrderReqList.length > 0">
                    <input type="checkbox" class="w-4 h-4 cursor-pointer" v-model="orderReqSelectAll" >
                    <div class="text-text1 pl-1 heading-6 cursor-pointer">
                      {{!orderReqSelectAll ? 'All' : 'Null'}}
                    </div>
                  </span>
                </div>
              </div>
              <div style="display:flex" class=" text-text2 bg-white border border-gray-500 rounded-md px-2 py-1 items-center h-7" v-if="showOrderReqLoader">
                <span class="mr-2 cursor-pointer"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                <input class="focus:outline-none w-96 text-text2" autocomplete="off" type="text" name="" placeholder="Item Name Search" id="custSearchInput2"
                  v-model="orderReqSearch"
                >
                <span v-if="orderReqSearch.length > 0" class="cursor-pointer"  @click="clearOrderReq()">
                  <i class="fas fa-times h-4 w-4 text-gray4"></i>
                </span>
              </div>
              <div class="overflow-auto" style="max-height: 280px !important;" v-if="parentOrderReqList.length > 0">
                <div v-for="(data, index) in parentOrderReqList" :key="index">
                  <div class="centerLine" @click="selectData(data, index, 'itemNameList')">
                    <input type="checkbox" class="h-4 w-4 cursor-pointer" v-model="data.isSelected" >
                    <p class=" text-text1 pl-1 heading-6 cursor-pointer overFlowParaA w-96">{{data.displayName}}</p>
                  </div>
                </div>
              </div>
              <div class="layout" v-if="parentOrderReqList.length === 0 && isRefresh">
                <div class="flex p-1 text-sm text-text1 bg-gray2 rounded items-center" role="alert">
                  <div>
                    <p class=" font-medium heading-5">No record found.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white container" v-if="showCustomDatepicker" >
      <div class="popup_overlay">
        <CustomDateSelector :appliedFilters="filterObject" @closeSelectedDialog="customDateListener" v-if="showCustomDatepicker"/>
      </div>
    </div>
</div>
</template>
<script>
import deboucneMixin from '@/mixins/debounce.js'
import moment from 'moment'
import API from '@/api/App.js'
import CustomDateSelector from '@/View/components/customDateSelection'
import {getDayAccordingToLimits} from '@/utils/convertDateAndTime.js'
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
    CustomDateSelector,
  },
  mixins: [deboucneMixin],
  data () {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          height: 'auto',
          stacked: true,
        },
        plotOptions: {
          bar: {
            columnWidth: '',
            horizontal: false,
            dataLabels: {
              total: {
                enabled: false,
                offsetX: 0,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: function (val) {
              return val + ""
            }
          }
        },
        // yaxis: {
        //   min: 0,
        //   max: 1200,
        //   tickAmount : 20
        // },
        tooltip: {
          custom: function({series, seriesIndex, dataPointIndex, w}) {
            console.log('series', series)
            console.log('seriesIndex', seriesIndex)
            console.log('dataPointIndex', dataPointIndex)
            console.log('w', w)
            return '<ul style="padding:4px;">' +
            '<li style="display:flex"><b>Order Source</b>: <p style="width:200px !important;overflow-wrap: anywhere;white-space: pre-wrap;">' + w.config.xaxis.categories[dataPointIndex] + '</p></li>' +
            '<li><b>' + w.config.series[seriesIndex].name + '</b>: $' + w.config.series[seriesIndex].data[dataPointIndex].toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '</li>' +
            '</ul>';
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
      series: [],
      showOrderReqLoader: false,
      tempItemNameList: [],
      orderReqSelectAll: false,
      isFilterOpen: true,
      isRefresh: true,
      orderReqSearch: '',
      searchLoader: false,
      ExtFListingObj: [],
      parentOrderReqList: [],
      requiredDataListing: [],
      itemListDebounce: () => {},
      isOrderReqLoading: false,
      titleObj: [
        {title: 'Order Source', icon: '', value: 'orderSource', sort: false},
        {title: 'Profit Margin', icon: '', value: 'profitMargin', sort: false},
        {title: 'Sum of Item Cost', icon: '', value: 'itemCost', sort: false},
        {title: 'Sum of Order Shipping Total Cost', icon: '', value: 'orderShippingTotalCost', sort: true},
        {title: 'Sum of Order Tax Paid', icon: '', value: 'orderTaxPaid', sort: false},
        {title: 'Sum of Order Total Paid', icon: '', value: 'orderTotalPaid', sort: false},
      ],
      pagination: {
        sortBy: 'orderShippingTotalCost',
        descending: true
      },
      buttonFiltersArray: [
        {
          title: 'Today',
          selected: false,
          value: 'today'
        },
        {
          title: 'Yesterday',
          selected: false,
          value: 'yesterday'
        },
        {
          title: 'Last Week',
          selected: false,
          value: 'lastWeek'
        },
        {
          title: 'Last 7 Days',
          selected: false,
          value: 'thisWeek'
        },
        {
          title: 'Last Month',
          selected: false,
          value: 'lastMonth'
        },
        {
          title: 'Last 30 Days',
          selected: true,
          value: 'last30Days'
        },
        {
          title: 'Last 60 Days',
          selected: false,
          value: 'last60Days'
        },
        {
          title: 'Last 90 Days',
          selected: false,
          value: 'last90Days'
        },
        {
          title: 'Last 120 Days',
          selected: false,
          value: 'last120Days'
        },
        {
          title: 'Custom',
          selected: false,
          value: 'custom'
        }
      ],
      selectedValue: 'last30Days',
      filterObject: {
        itemNameList: [],
        startDate: '',
        endDate: '',
        minDate: '',
        maxDate: '',
      },
      cardHight: 0,
      mainHeight: 0,
      defaultStartDate: '',
      defaultEndDate: '',
      showCustomDatepicker: false,
      firstLoading: false,
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    this.itemListDebounce = this.debounce(function () {
      this.getItemList(false)
    }, 500)
    this.cardHight = window.innerHeight - 171
    this.mainHeight = window.innerHeight - 90
    console.log('this.cardHight', this.cardHight, this.mainHeight)
    this.defaultEndDate = new Date()
    let currentDate = new Date()
    this.defaultStartDate = new Date(currentDate.setDate(currentDate.getDate() - 29))
    this.filterObject.startDate = this.defaultStartDate
    this.filterObject.endDate = this.defaultEndDate
    this.apiCaller()
  },
  watch: {
    orderReqSearch: {
      handler() {
        this.itemListDebounce()
      }
    },
    tempItemNameList: {
      handler () {
        console.log('tempItemNameList', this.tempItemNameList)
        this.filterObject.itemNameList = []
        this.tempItemNameList.forEach(a => {
          if (a.displayName !== '') {
            this.filterObject.itemNameList.push(a.displayName)
          }
        })
      },
      deep: true
    },
    filterObject: {
      handler () {
        console.log('filterObject', this.filterObject)
      },
      deep: true
    },
  },
  methods: {
    selectedAll () {
      this.tempItemNameList = []
      if (this.orderReqSelectAll === true) {
        this.orderReqSelectAll = false
      } else {
        this.orderReqSelectAll = true
      }
      if (this.orderReqSearch === '') {
        if (this.orderReqSelectAll) {
          console.log('stage 1')
          this.tempItemNameList = []
          for (let index = 0; index < this.parentOrderReqList.length; index++) {
            this.parentOrderReqList[index].isSelected = true
          }
        } else if (!this.orderReqSelectAll) {
          this.tempItemNameList = []
          for (let index = 0; index < this.parentOrderReqList.length; index++) {
            this.parentOrderReqList[index].isSelected = false
            this.tempItemNameList = []
          }
        }
      } else if (this.orderReqSearch !== '') {
        if (this.orderReqSelectAll) {
          console.log('stage 3')
          for (let index = 0; index < this.parentOrderReqList.length; index++) {
            if (this.parentOrderReqList[index].isSelected === false) {
              this.parentOrderReqList[index].isSelected = true
              this.tempItemNameList.push(this.parentOrderReqList[index])
            }
          }
        } else if (!this.orderReqSelectAll) {
          console.log('stage 4')
          this.tempItemNameList = []
          for (let J = 0; J < this.parentOrderReqList.length; J++) {
            this.parentOrderReqList[J].isSelected = false
          }
        }
      }
    },
    resetAll () {
      this.filterObject.startDate = this.defaultStartDate
      this.filterObject.endDate = this.defaultEndDate
      this.resetOrderReq()
      for (let i = 0; i < this.buttonFiltersArray.length; i++) {
        if (this.buttonFiltersArray[i].value === 'last30Days') {
          this.selectedValue = 'last30Days'
          this.buttonFiltersArray[i].selected = true
        } else {
          this.buttonFiltersArray[i].selected = false
        }
      }
    },
    resetOrderReq () {
      this.orderReqSearch = ''
      this.orderReqSelectAll = false
      this.filterObject.itemNameList = []
      this.tempItemNameList = []
      for (let index = 0; index < this.parentOrderReqList.length; index++) {
        this.parentOrderReqList[index].isSelected = false
      }
    },
    clearOrderReq () {
      this.orderReqSearch = ''
      // this.getItemList(false)
    },
    openCloseFilter () {
      this.isFilterOpen = !this.isFilterOpen
      console.log('openCloseFilter')
    },
    customDateListener (data) {
      console.log('datadatadatadatadatadata', data)
      this.showCustomDatepicker = false
      if (data !== null) {
        this.filterObject.startDate = data.startDate
        this.filterObject.endDate = data.endDate
        // this.getListing()
      }
    },
    selectData (data, index) {
      if (!this.orderReqSelectAll) {
        data.isSelected = !data.isSelected
        if (data.isSelected) {
          this.tempItemNameList.push(data)
        } else {
          const findex = this.tempItemNameList.findIndex(x => x.displayName === data.displayName)
          this.parentOrderReqList[index].isSelected = false
          this.tempItemNameList.splice(findex, 1)
        }
      } else {
        data.isSelected = !data.isSelected
        this.tempItemNameList = []
        for (let I = 0; I < this.parentOrderReqList.length; I++) {
          if (this.parentOrderReqList[I].isSelected === true) {
            this.tempItemNameList.push(this.parentOrderReqList[I])
          }
          this.orderReqSelectAll = false   
        }
      }
    },
    getDateTimeForFilterHandler (index, showCustomDatepicker) {
      this.selectedValue = this.buttonFiltersArray[index].value
      console.log('showCustomDatepicker', index, showCustomDatepicker)
      for (let i = 0; i < this.buttonFiltersArray.length; i++) {
        if (i !== index) {
          this.buttonFiltersArray[i].selected = false
        } else {
          this.buttonFiltersArray[i].selected = true
        }
      }
      if (this.buttonFiltersArray[index].value !== 'today' && this.buttonFiltersArray[index].value !== 'custom') {
        getDayAccordingToLimits(this.buttonFiltersArray[index].value).then(val => {
          console.log('val123123', val)
          this.filterObject.startDate = val.startDate
          this.filterObject.endDate = val.endDate
          // this.getListing()
        })
      } else {
        let date = new Date(new Date().setHours(0, 0, 0, 0)).toUTCString()
        if (this.buttonFiltersArray[index].value === 'today') {
          this.filterObject.startDate = date
          this.filterObject.endDate = date
          // this.getListing()
        } else {
          this.showCustomDatepicker = showCustomDatepicker
        }
      }
    },
    applyFilter() {
      this.getListing()
    },
    apiCaller () {
      this.isRefresh = false
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading ...'})
      API.ShipworkReqData(
        response => {
          this.requiredDataListing = response.Data === null ? [] : response.Data
          console.log('this.requiredDataListing', this.requiredDataListing)
          this.filterObject.minDate = this.requiredDataListing.minDate
          this.filterObject.maxDate = this.requiredDataListing.maxDate
          this.isRefresh = true
          this.getItemList()
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
        },
        error => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getItemList () {
      this.isRefresh = false
      this.isOrderReqLoading = true
      API.ShipworkOrderRequestedListApi(
        this.orderReqSearch,
        response => {
          this.parentOrderReqList = response.Data === null ? [] : response.Data
          this.isRefresh = true
          if (this.tempItemNameList.length > 0 && this.filterObject.itemNameList.length > 0) {
            for (let I = 0; I < this.parentOrderReqList.length; I++) {
              for (let J = 0; J < this.tempItemNameList.length; J++) {
                if (this.tempItemNameList[J].displayName === this.parentOrderReqList[I].displayName) {
                  this.parentOrderReqList[I].isSelected = true
                }
              }
            }
          }
          this.isOrderReqLoading = false
        },
        error => {
          this.isOrderReqLoading = false
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getListing () {
      this.firstLoading = false
      console.log('this.filterObject.startDate 123 123', this.filterObject.startDate)
      console.log('this.filterObject.endDate 123 123', this.filterObject.endDate)
      let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
      let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait, it takes some time ...'})
      this.searchLoader = true
      if (this.filterObject.itemNameList.length === 0) {
        this.filterObject.itemNameList = []
      }
      API.AmazonCogsByChannelListing(
        utcSTART,
        utcEND,
        this.filterObject.itemNameList,
        false,
        response => {
          this.searchLoader = false
          this.firstLoading = true
          this.ExtFListingObj = response.Data === null ? [] : response.Data
          console.log(response.Data)
          if (this.ExtFListingObj.length === 1) {
            this.chartOptions.plotOptions.bar.columnWidth = '20%'
          } else {
            this.chartOptions.plotOptions.bar.columnWidth = '70%'
          }
          this.addTitle()
          for (let index = 0; index < this.ExtFListingObj.length; index++) {
            this.chartOptions.xaxis.categories.push(this.ExtFListingObj[index].orderSource)
            this.series[0].data.push(this.ExtFListingObj[index].orderTotalPaid)
            this.series[1].data.push(this.ExtFListingObj[index].itemCost)
            this.series[2].data.push(this.ExtFListingObj[index].orderShippingTotalCost)
            this.series[3].data.push(this.ExtFListingObj[index].orderTaxPaid)
            this.series[4].data.push(this.ExtFListingObj[index].profitMargin)
          }
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
        },
        error => {
          this.resetPaginationValues = false
          this.searchLoader = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    addTitle () {
      this.series = []
       let chartTitle = ['Sum of Order Total Paid', 'Sum of Item Cost', 'Sum of Order Shipping Total Cost', 'Sum of Order Tax Paid', 'Profit Margin']
       for (let index = 0; index < chartTitle.length; index++) {
         this.series.push({
           name: chartTitle[index],
           data: []
         })
       }
    },
  },
  destroyed() {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.small_col2 {
  min-width: 280px !important;
  width: 280px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col {
  min-width: 190px !important;
  width: 190px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.smallx_col {
  min-width: 150px !important;
  width: 150px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.progress-bar {
  height: 2px;
  background-color: #0a7ef9;
  width: 100%;
  overflow: hidden;
}

.button_position {
  position: absolute;
  z-index: 10!important;
  height: 0px!important;
  /* position: fixed; */
}
.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #74b4f9;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
.loader2 {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1f48c2;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1.2s linear infinite;
}
.centerLine {
  display: flex !important;
  align-items: center !important;
}
.xsmall_col {
  min-width: 54px !important;
  width: 54px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
